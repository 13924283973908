// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("lodash")

//= require select2-full


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "channels"

import ahoy from "ahoy.js";


import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/button'
import 'bootstrap/js/src/carousel'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/modal'
import 'bootstrap/js/src/popover'
import 'bootstrap/js/src/scrollspy'
import 'bootstrap/js/src/tab'
import 'bootstrap/js/src/toast'
import 'bootstrap/js/src/tooltip'

import Cookies from 'js-cookie';


import "jquery-ui/ui/effect";
import 'jquery-ui/ui/widgets/sortable.js'
import 'jquery-jsonview/dist/jquery.jsonview.js'

import "chartkick/chart.js"


(function removeFromArrayPolyfill() {
  if (window.Array.prototype.remove) return;

  Array.prototype.remove = function (value) {
    if (!this.length || !value) return;

    const indexOfValue = this.indexOf(value);

    if (indexOfValue >= 0) {
      this.splice(indexOfValue, 1);
    }
  };
})();



require("util/dark");
require("util/grid");
require("util/countdown_clock");
require("util/pre.jsonView");
require("util/remote_popup");
require("util/nested-details");
require("util/taggable");
require("util/dropzone");
require("util/social");


Rails.start()

ahoy.trackAll();

// ahoy.trackView();
// ahoy.trackClicks();
// ahoy.trackSubmits();



window.Cookies = Cookies;




